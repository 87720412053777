// .enrollmentcount-card,
.coursecount-card,
.course-completed {
  background-color: #702DFF;
  padding: 1px;
  border-radius: 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 300px;
  height: 70px;
  animation: fadeInUp 0.5s ease both;
}

.coursecount-card {
  background-color: #9B59B6;
}

.course-completed {
  background-color: #F39C12;
}

.cards-count {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  animation: scaleIn 0.5s ease both;
}

.circle-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.circle-icon:hover {
  background-color: #ffffff; /* Change to your desired hover color */
}

.settings-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.settings-icon:hover {
  transform: rotate(90deg); /* Change to your desired hover transformation */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
