.login-main {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  overflow: hidden;
  
    

  

}

.loginScreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  justify-content: center;
}

.login-image {
  display: none;
}

@media (min-width: 768px) {
  .login-main {
    flex-direction: row;
    

    
  }

  .loginScreen {
    padding: 100px;
  }

  .login-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }

  .login-image img {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}

.formLabel {
  margin-right: 8px;
  text-align: left;
}

.loginScreen-content {
  text-align: center;
}

.loginScreen-Box3 {
  margin-bottom: 20px;
}

.loginScreen-logo {
  width: 100px;
  height: auto;
}

.loginScreen-txt1 {
  margin-bottom: 10px;
  font-size: 20px;
}

.forgotpassword {
  text-align: right;
  margin-top: -10px;
}
