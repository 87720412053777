// @use "../../config/variables.scss";

// .main-box {
//   padding: 20px;

//   .card-titleone {
//     font-size: 1.2rem;
//     font-weight: bold;
//   }

//   .card-titletwo {
//     font-size: 1rem;
//     color: #666;
//   }

//   .cat-card {
//     margin-bottom: 20px;
//   }

//   .ant-card {
//     width: 100%;
//     margin-bottom: 20px;
//   }

//   .ant-card-body {
//     padding: 15px;
//   }

//   .ant-pagination {
//     text-align: center;
//     margin-top: 20px;
//   }
// }

// @media (max-width: 576px) {
//   .main-box {
//     padding: 10px;
//     overflow-x: hidden;
//     overflow-y: auto;
//     white-space: normal;

//     .card-titleone {
//       font-size: 1rem;
//     }

//     .card-titletwo {
//       font-size: 0.9rem;
//     }

//     .cat-card {
//       margin-bottom: 15px;
//     }

//     .ant-card-body {
//       padding: 10px;
//     }

//     .ant-pagination {
//       margin-top: 15px;
//     }
//   }
// }


@use "../../config/variables.scss";

.main-box {
  padding: 20px;

  .card-titleone {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .card-titletwo {
    font-size: 1rem;
    color: #666;
  }

  .cat-card {
    margin-bottom: 20px;
  }

  .ant-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .ant-card-body {
    padding: 15px;
  }

  .ant-pagination {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .main-box {
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: normal;

    .card-titleone {
      font-size: 1rem;
    }

    .card-titletwo {
      font-size: 0.9rem;
    }

    .cat-card {
      margin-bottom: 15px;
    }

    .ant-card-body {
      padding: 10px;
    }

    .ant-pagination {
      margin-top: 15px;
    }
  }
}

