@use "../../config/variables.scss" as vars;

.NoData-noDataText {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // font-family: vars.$interstate_regular;
  font-size: 18px;
  font-weight: 600;
  color: #b9b9b9;
}
.NoData-img {
  height: 100px;
  object-fit: cover;
}

.NoData-Text {
  color: black;
  // font-family: vars.$interstate_regular;
  font-size: 16px;
  margin-top: 10px;
}
.NoData-Text2 {
  // color: vars.$grey1;
  // font-family: vars.$interstate_regular;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}
