@use "../config/variables.scss" as vars;

.LayoutHeader {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  height: 9vh;
}

.LayoutHeader-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: vars.$Bold;
  color: vars.$primary;
  font-size: 20px;
}

// .sideBar-Box {
//   height: 100vh;
//   overflow: scroll;

//   @media screen and (max-width: 1024px) {
//     width: 200px; /* adjust width for tablets */
//   }

//   /* For mobile phones */
//   @media screen and (max-width: 767px) {
//     width: 100%; /* full width for mobile phones */
//   }
// }

// .sideBar-Box {
//   width: 250px; /* default width for desktop */

//   @media screen and (max-width: 1024px) {
//     width: 200px; /* adjust width for tablets */
//   }

//   /* For mobile phones */
//   @media screen and (max-width: 767px) {
//     width: 100%; /* full width for mobile phones */
//   }
// }



.sideBar-Box {
  height: 100vh;
  overflow: hidden;
}

.toggleButton {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0px 0px;
}
.sideBar-logo {
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
  width: 50%;

  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
}

.sideBar-txt1 {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #45664c;
  font-family: vars.$Regular;
  font-size: 12px;
  color: #ffffff64;
  margin-left: 15px;
  margin-right: 15px;
}

.sideBar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px;
  height: 50px;
  color: #fff;
  font-family: vars.$SemiBold;

  &:hover {
    background-color: #fff0c932;
    border-radius: 5px;
    color: rgb(233, 233, 233);
    cursor: pointer;
  }

  &.active {
    background-color: #fff0c932;
    border-radius: 5px;
  }
}

.siderBox {
  background-color: red;
}

.sideBar-logo-toggle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.large-logo{
  width: 50%;
}

