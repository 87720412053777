.first-container {
  width: 180vh;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: whitesmoke;
  margin-left: 20px;
}

.second-container {
  width: 180vh;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  margin-left: 20px;
}

.user-card {
  height: 100%;
  background-color: #d7d7ff;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.user-card:hover {
  transform: translateY(-5px);
}
