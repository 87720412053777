//colors

// $primary: "#04286f";
// $secondary: "#DDDDED";
// $tertiary: "#EBE4FF";
// $quartenery: "#D4D2E8";
// $black: "#000";
// $white: "#FFFF";

$primary: #04286f;
$primary1: #DDDDED;
$secondary: #dddded;
$secondary1: #08493e;
$tertiary: "#EBE4FF";
$tertiary1: #f9da7f;
$quartenery: "#D4D2E8";
$quartenery1: #42d3e1;
$black: "#000";
$white: #ffffff;

//  fonts
$Bold: "Montserrat-Bold";
$SemiBold: "Montserrat-SemiBold";
$Light: "Montserrat-Light";
$Medium: "Montserrat-Medium";
$Regular: "Montserrat-Regular";
