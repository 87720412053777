.quiz-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  background-color: #f5f5f5;
}

.question-container {
  width: 600px; 
  min-height: 400px; 
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.question {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.options {
  list-style: none;
  padding: 0;
}

.options li {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #eaeaea;
  border-radius: 5px;
  cursor: pointer;
}

.options li.selected {
  background-color: #d4edda;
}

.submit-button,
.next-button,
.restart-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.message {
  margin-top: 20px;
  font-size: 1em;
}



.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.pass {
  color: green;
}

.fail {
  color: red;
}

.button-transition {
  transition: opacity 0.3s ease-in-out;
}

.quiz-container {

  .quiz-heading {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
}


